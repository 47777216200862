<template>
  <v-footer
    id="default-footer"
    color="transparent"
    absolute
    app
    inset
  />
</template>

<script>
// Components

  export default {
    name: 'DefaultFooter',

    components: {},
  }
</script>
